/* The private messages in the main navbar */
.dropdown-messages {
    min-width: 20em;
    .message-subject {
        font-style: italic;
    }
    .author-name {
        font-weight: bold;
    }
}

/* Sidebar Nav */
.sidebar {
    padding-top: 1em;
    padding-bottom: 1em;
    text-shadow: none;
    background-color: $fbb-navigation-bg;
    border: 1px solid $border-color;

    .sidenav {
        display: block;
    }

    .sidenav-header {
        padding-left: 1.25em;
        padding-bottom: 1em;
        font-size: 12px;
        font-weight: bold;
        line-height: 20px;
        color: $black;
        text-transform: uppercase;
    }

    .sidenav-btn {
        padding-bottom: 1em;
        text-transform: uppercase;
        text-align: center;
    }

    .nav-item > a {
        display: block;
    }

    .nav-item > a:hover,
    .nav-item > a:focus {
        text-decoration: none;
        background-color: $fbb-navigation-hover-color;
    }

    .nav-item > .active,
    .nav-item > .active:hover,
    .nav-item > .active:focus {
        font-weight: normal;
        color: $fbb-navigation-color;
        background-color: $fbb-navigation-hover-color;
    }
}

.nav-sidebar {
    width: 100%;
    padding: 0;

    .nav-header {
        position: relative;
        display: block;
        padding: 10px 7.5px;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .nav-item {
        width: 100%;
    }

    .nav-link {
        color: $fbb-navigation-color;

        &:hover {
            background-color: $fbb-navigation-bg;
        }

        &.active {
            border-top: 1px solid $border-color;
            border-bottom: 1px solid $border-color;

            cursor: default;
            background-color: $fbb-navigation-bg;
        }
    }

    .nav-item:first-child > .nav-link:first-child.active {
        border-top: none;
    }
}

.card-tabs {
    > .card-header {
        padding: 0;
        font-weight: 500;
    }
    > .card-header > .nav-tabs {
        border-bottom: none;

        .nav-link {
            border-radius: 0;
            border-color: transparent;
        }

        .nav-link.active,
        .nav-item.show .nav-link {
            background-color: $fresh-blue;
        }

        // non-active and hover
        .nav-item {
            .nav-link {
                color: $fbb-header-subtitle-color;
                border: 1px solid $blue;

                // different background color when hovering
                &.active,
                &:hover,
                &:focus {
                    background-color: $fresh-blue;
                    border: 1px solid $fresh-blue;
                }
            }
        }
    }
}
