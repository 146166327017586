// bootstrap5 colors
//$blue: #0d6efd;
//$indigo: #6610f2;
//$purple: #6f42c1;
//$pink: #d63384;
//$red: #dc3545;
//$orange: #fd7e14;
//$yellow: #ffc107;
$green: #198754;
//$teal: #20c997;
//$cyan: #0dcaf0;

// flaskbb + bootstrap3 colors
$blue: #337ab7;
//$green: #5cb85c;
$red: #d9534f;
$fresh-blue: #0088cc;
$light-blue: #5bc0de;
$dark-green: #3C763D;
$orange: #f0ad4e;
$gray: #555;
$black: #000;
$white: #fff;


// FlaskBB Variables
$fbb-color: #212529; // bs5 gray-900
$fbb-bg: #f6f9fc;
$fbb-border-color: #cad7e1;
$fbb-active-bg: #e7e7e7;
$fbb-link-color: #337ab7;
$fbb-link-hover-color: #275b89;

$fbb-bg-dark: #f6f9fc;
$fbb-bg-light: #fff;


// header && footer colors
$fbb-header-title-color: #fff;
$fbb-header-subtitle-color: #E8F1F2;
$fbb-header-bg-primary: #0088cc;  // old: #3276b1
$fbb-header-bg-secondary: #285e8e;
$fbb-footer-bg: #333333;

$fbb-navigation-color: #555;
$fbb-navigation-bg: #f8f8f8;
$fbb-navigation-hover-color: #e7e7e7;


// page colors
$page-bg: #fff;            // page body background
$page-head-bg: #f5f5f5;    // page head background
$page-meta-bg: #eaf1f5;    // page meta background
$page-meta-border: $fbb-border-color;   // page meta (bottom) border
$page-border: $fbb-border-color;        // page border (all over)
$page-hover: #f8f8f8;                // page-row hover


// post colors
$post-box-bg: $page-bg;
$post-box-border: $fbb-border-color;
$post-meta-border: $page-meta-bg;
$post-meta-bg: $page-bg;
$post-signature-border: $page-meta-bg;
$post-footer-border: $fbb-border-color;
$post-footer-bg: $page-bg;

$author-box-bg: #e8ecf1;
$author-online: $green;
$author-offline: $gray;


// Bootstrap Variables

//
// For more variables check out this file:
// https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss
// raw: https://raw.githubusercontent.com/twbs/bootstrap/main/scss/_variables.scss
//
$body-bg:                   $fbb-bg;
$body-color:                $fbb-color;

// Links
$link-color:                              $fbb-link-color;
$link-decoration:                         none;
$link-shade-percentage:                   20%;
$link-hover-decoration:                   underline;


// Border
$border-width:                1px;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px
);

$border-color:                $fbb-border-color;
$border-radius:               .25rem;
$border-radius-sm:            .2rem;
$border-radius-lg:            .3rem;
$border-radius-pill:          50rem;


// Cards
$card-border-radius: none;


// Navbar
$navbar-light-color:                $fbb-color;
$navbar-light-hover-color:          $fbb-color;
$navbar-light-active-color:         $fbb-color;


// Forms
$input-bg: $white;  // default is $body-bg
