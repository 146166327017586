// Management Panel
.management {
    .search-form {
        display: none;
        padding: 15px;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    .management-header {
        background-color: $blue;
    }

    .management-body {
        padding: 0;
    }
}

.settings {
    border: none;
    margin-bottom: 0;

    .settings-header {
        background-color: $page-hover;
        border-bottom: 1px solid $border-color;
    }
    .settings-body {
        padding: 0;
    }

    .settings-meta {
        background-color: $page-meta-bg;
        margin: 0;
        padding: 5px 0 5px 0;
        border-bottom: 1px solid $border-color;
        .meta-item {
            font-weight: bold;
        }
    }
    .settings-content > .category {
        border-left: none;
        border-right: none;
        border-bottom: none;
        margin-bottom: 0;
        &:first-child {
            border-top: none;
        }
        &:last-child {
            border-bottom: 1px solid $page-border;
            margin-bottom: 1em;
        }
    }
    .settings-row {
        padding: 5px 0 5px 0;
        margin: 0;

        &:last-child {
            padding-bottom: 10px;
            border-bottom: none !important;
        }

        &.hover:hover {
            background-color: $page-hover;
        }

        .btn-icon {
            padding: 0 6px;
        }
    }

    .settings-footer {
        padding-top: 5px;
        padding-left: 5px;
        padding-bottom: 0px;
        .pagination {
            margin: 0;
        }
    }
}

.with-left-border {
    border-left: 1px solid $border-color;
}

.with-border-bottom {
    border-bottom: 1px solid $border-color;
}

.stats {
    margin-top: 15px;
    margin-bottom: 15px;

    .stats-widget {
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
        //background-color: $page-hover;
        border: 1px solid $border-color;

        .icon {
            display: block;
            font-size: 96px;
            line-height: 96px;
            margin-bottom: 10px;
            text-align: center;
        }
        var {
            display: block;
            height: 64px;
            font-size: 64px;
            line-height: 64px;
            font-style: normal;
        }
        label {
            font-size: 17px;
        }
        .options {
            margin-top: 10px;
        }
    }

    .stats-heading {
        font-size: 1.25em;
        font-weight: bold;
        margin: 0;
        border-bottom: 1px solid $border-color;
    }

    .stats-row {
        margin: 0 0 15px 0;
        padding-bottom: 15px;
        //border-bottom: 1px solid $border-color;

        .stats-item {
            margin: 0;
            padding-top: 5px;
        }

        &:last-child {
            border: none;
        }
    }
}

.alert-message {
    margin: 0;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid $dark-green;
    border-left: 3px solid #eee;
    h4 {
        margin-top: 0;
        margin-bottom: 5px;
    }
    p:last-child {
        margin-bottom: 0;
    }
    code {
        background-color: #fff;
        border-radius: 3px;
    }

    &.alert-message-success {
        background-color: #f4fdf0;
        border-color: $dark-green;
    }
    &.alert-message-success h4 {
        color: $dark-green;
    }
    &.alert-message-danger {
        background-color: #fdf7f7;
        border-color: $red;
    }
    &.alert-message-danger h4 {
        color: $red;
    }
    &.alert-message-warning {
        background-color: #fcf8f2;
        border-color: $orange;
    }
    &.alert-message-warning h4 {
        color: $orange;
    }
    &.alert-message-info {
        background-color: #f4f8fa;
        border-color: $light-blue;
    }
    &.alert-message-info h4 {
        color: $light-blue;
    }
    &.alert-message-default {
        background-color: #eee;
        border-color: $gray;
    }
    &.alert-message-default h4 {
        color: #000;
    }
    &.alert-message-notice {
        background-color: #fcfcdd;
        border-color: #bdbd89;
    }
    &.alert-message-notice h4 {
        color: #444;
    }
}
